footer {
    display: flex;
    /* justify-content: space-around; */
    position: relative;
    overflow: hidden;
    background-image: url('/public/img/free_img/footer_background.webp');
    width: 100vw;
    padding: 10vw 5.9523809524vw;
    color: #cccccc;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100%;
}

footer .left {
    width: 30vw;
    display: inline-block;
    /* float: left; */
    position: relative;
    margin-left: 6vw;

}

footer .right {
    display: inline-block;
    /* float: right; */
    position: relative;
    margin-right: 6vw;
    width: 35vw;
}

footer .left h2 {
    font-size: 3.5vw;
    line-height: 3.8vw;
    font-weight: 900;
    margin-bottom: 2vw;
    margin-top: 0;
    color: #fff;
}

footer .left h3 {
    font-size: 1.6vw;
    line-height: 1.8vw;
    font-weight: 300;
    margin-bottom: .5vw;
}

#footer-info .contact_hours,
#footer-info .contact_phone,
#footer-info .email {
    display: block;
    text-decoration: none;
    color: #cccccc;
    font-weight: 300;
    font-size: 1.6vw;
    line-height: 1.8vw;
    margin-top: 2vw;
    margin-bottom: .5vw;
    outline: 0;
}

#footer-info .email {
    font-size: 1vw;
    line-height: 1.2vw;
    margin-top: .5vw;
}

#footer-info a:hover {
    cursor: pointer;
    outline: 0;
}

#footer-info .socialmedia a {
    display: inline-block;
}

#footer-info .socialmedia {
    margin-top: 2vw;
}

#footer-info #copyright {
    font-size: .8vw;
    line-height: 1vw;
    font-weight: 300;
    margin-top: 2vw;
}



@media screen and (max-width: 800px) {
    footer {
        display: block;
    }

    footer .left,
    footer .right {
        width: 70vw;
        display: block;
        margin: 2vw 0;
    }

    #contact-form {
        margin-left: 0;
    }


    #footer-info {
        display: none;
    }

    #contact-form ul {
        padding-left: 0vw;
    }
}