.float-register {
    position: fixed;
    right: 0;
    bottom: 38vh;
    z-index: 99;
    width: 15vw;
    cursor: pointer;
}

.float-register img {
    width: 100%;
}

.float-whatsapp {
    position: fixed;
    left: 1.5vw;
    bottom: 3vw;
    z-index: 99;
    width: 5vw;
    cursor: pointer;
}

.float-whatsapp img {
    width: 100%;
}