header {
    display: block;
    position: fixed;
    width: 100vw;
    overflow: hidden;
    z-index: 1;
    height: 7vw;
    background-color: rgba(255, 255, 255, .8);
    opacity: 1;
}

header h1 {
    display: block;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    width: 26.30952381vw;
    top: 0;
    left: 3.4404761905vw;
    margin: 1.2vw;
}

header h1 img {
    display: block;
    width: 80%;
    height: auto !important;
    margin: 0 auto;
}

header h2 {
    display: inline-block;
    margin: 0px;
    font-style: italic;
    font-weight: 500;
    font-size: 1.2vw;
    color: #186bb3;
    z-index: 2;
    position: absolute;
    top: 4.45vw;
    left: 28.30952381vw;
}

header .socialmedia {
    display: inline-block;
    position: absolute;
    top: 2.3vw;
    right: 8.4404761905vw;
    height: 100%;
    z-index: 2;
}

.socialmedia a img {
    width: 3vw;
    height: 3vw;
}

.socialmedia a {
    margin: .5vw;
}