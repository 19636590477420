.services-overviews {
    background-image: url('/public/img/free_img/services_background.jpg');
    padding: 2vw;
    padding-bottom: 6vw;
    position: relative;
    overflow: hidden;
    color: #ccc;
}

.services-overviews-title {
    color: white;
    font-size: 2.3vw;
    font-weight: 400;
    margin: 0 10%;
    margin-bottom: 2vw;
    padding-bottom: 1vw;
    border-bottom: 1px solid #b9b9b9;
}

.services-overviews ul {
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    width: 80%;
}

.services-overviews li {
    position: relative;
    list-style: none;
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    width: 30%;
}


.services-overviews .service-icon {
    width: 5vw;
    margin-right: 1vw;
    float: left;
    display: inline-block;
}

.services-overviews .service-title {
    display: inline-block;
    color: white;
    max-width: 70%;
    font-size: 1.6vw;
    line-height: 1.8vw;
    height: 3vw;
}

.services-overviews .service-description {
    display: inline-block;
    float: right;
    width: 80%;
}