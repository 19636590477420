.instagram-section {}

.instagram-section .instagram-title {
    text-align: center;
    font-size: 2.3vw;
    line-height: 2.5vw;
}

.instagram-section .instagram-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    margin: 2vw 5vw;
}

.instagram-section .instagram-post {
    position: relative;
    display: inline-block;
}

.instagram-section .instagram-post:hover {
    cursor: pointer;
}

.instagram-section .instagram-post iframe {
    border: none;
    overflow: hidden;
    width: 26vw;
    height: 30vw;
    pointer-events: none;
}