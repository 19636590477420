.carrousel-home ul {
    padding: 0;
    height: 100vh;
    overflow: hidden;
    margin: 0;
}

.carrousel-home .carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}

.carrousel-home .carousel-image.active {
    opacity: 1;
}

.carrousel-home .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.carrousel-home .home-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}