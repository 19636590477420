#contact-form {
    display: block;
    position: relative;
    width: 100%;
    margin-left: 6vw;
}

#contact-form ul {
    list-style: none;
    padding-left: 4vw;
}

#contact-form ul li {
    display: inline-block;
    width: 46%;
    margin-right: 4%;
    margin-bottom: 1vw;
}

#contact-form ul .li_msg {
    display: block;
    float: none;
    width: 98%;
    margin-bottom: 1vw;
}

#contact-form ul li label {
    display: block;
    color: #ccc;
    font-size: 1vw;
    font-weight: 600;
    margin-bottom: .5vw;
}

#contact-form ul li input,
#contact-form ul li textarea {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    font-size: 1vw;
    line-height: 1.2vw;
    color: #ccc;
    font-weight: 300;
    background: transparent;
    border: 1px solid #8d8e8e;
    border-width: 0 0 1px 0;
    padding-bottom: .5vw;
}

#contact-form ul li textarea {
    font-family: Raleway, sans-serif;
    height: 5vw;
}


#contact-form #submit-btn {
    display: inline-block;
    background: #13803b;
    font-size: .9vw;
    line-height: 1.4vw;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    padding: .5vw 1vw;
    border: 1px solid #13803b;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 1vw;
    margin-left: 4vw;
}

#contact-form #submit-btn:active {
    transform: scale(.95);
}

@media screen and (max-width: 600px) {}