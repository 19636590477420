.companies-container {
    position: relative;
    width: 100%;
    padding: 2vw 0;
}

.companies-container h2 {
    font-size: 2.3vw;
    line-height: 2.5vw;
    text-align: center;
}

.companies-container ul {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    width: 80%;
}

.company-icon-container {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
}

.company-icon-container img {
    aspect-ratio: 3/2;
    object-fit: contain;
    width: 80%;
}